#text_to_evaluate {
  padding: 0.5rem 1rem;
}

.suggested-text[readonly],
.text-to-evaluate-repeat[readonly] {
  background-color: #f1f5f9;
  color: #000000;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}

.accordion-item-head {
  background-color: #15803d;
  border: 1px solid #e2e8f0;
  color: #ffffff;
  border-radius: 0.375rem;
}

.accordion-item-head:hover {
  background-color: #15803d;
  border: 1px solid #e2e8f0;
}

.item-content-toggle {
  color: #000000;
  background-color: #ecfdf5;
  padding: 1rem 2rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-item-head > div:nth-child(2) {
  color: #ffffff !important;
  max-height: 40px;
  background-color: #064e3b !important;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin: auto 0 auto 0.65rem;
}

.accordion-item-head > div:nth-child(2):hover {
  color: #ffffff!important;
  background-color: #022c22!important;
}
