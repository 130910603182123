.termToDefine {
  padding-bottom: 1px;
  border-bottom: 3px dotted #009FCF ;
  z-index: 1000;
}

.termToDefine:hover {
  cursor: help;
}

.infoIcon {
  border: none;
  padding-bottom: 0;
}

.infoIcon svg {
  position: relative;
  top: -2px;
  vertical-align: middle;
 }

.LTHTooltip {
  font-size: 1rem;
  color: #063B46;
  background-color: #fbf1fc;
  /* background: linear-gradient(159deg, #B24FB0 0%, #c210bc 20%, #6B21A8 100%); */
  max-width: calc(100vw - 10px);
  z-index: 1000;
  opacity: 1;
  pointer-events: auto!important;
  padding: 1.5rem 2rem;
  /* box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
  /* box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2); */
  box-shadow: 0 0 #000000, 0 0 #000000, 0 10px 15px -3px rgb(0 0 0 / 0.4), 0 4px 6px -4px rgb(0 0 0 / 0.4);
  line-height: 1.6;
}

/* .LTHTooltip span::before {
    display: block;
    content: "\00d7";
    font-size: 2rem;
    text-align: right;
    position: absolute;
    top: 0;
    right: 5px;
    padding: 0 15px;
} */

.LTHTooltip a {
  text-decoration: underline;
  color: #063B46;
}

.LTHTooltip a.external-link::after {
  content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="rgb(0, 110, 207)" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>');
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 0.25rem;
  vertical-align: text-top;
}

.react-tooltip-arrow {
  background-image: none;
  background-color: #fbf1fc;
}


.LHTooltip_wrapper.evaluations .LTHTooltip {
  color: #ffffff;
  background-image: linear-gradient(to right bottom, #15803d, #116c34, #0e582a, #0b4521, #083318);
  border: 1px solid #0b4521;
}

.LHTooltip_wrapper.evaluations .react-tooltip-arrow {
  background-image: none;
  background-color: #0e582a;
}

.LHTooltip_wrapper.evaluation-medium .LTHTooltip {
  color: #ffffff;
  background-image: linear-gradient(to right bottom, #0081a1, #00718d, #006179, #005266, #004354);
  border: 1px solid #004354;
}

.LHTooltip_wrapper.evaluation-medium .react-tooltip-arrow {
  background-image: none;
  background-color: #005266;
}

.LHTooltip_wrapper.evaluation-high .LTHTooltip {
  color: #ffffff;
  background-image: linear-gradient(to right bottom, #be123c, #a40f34, #8a0d2c, #710a24, #59081c);
  border: 1px solid #59081c;
}

.LHTooltip_wrapper.evaluation-high .react-tooltip-arrow {
  background-image: none;
  background-color: #710a24;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {  }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  .LTHTooltip {
    max-width: 22rem;
  }
}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
