/* ==============================================
 * Resultset
 * ==============================================
*/
.resultset-item-head {
  display: flex;
  justify-content: space-between;
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  font-weight: 700;
  border-radius: 0.375rem;
  vertical-align: middle;
  line-height: 36px;
  padding: 0.375rem 0.75rem;
  /* cursor: pointer; */
  max-width: 100%;
  width: calc(100vw - 2rem);
}

.resultset-item-head:hover {
  background-color: #e2e8f0;
}

.resultset-item-head > div:first-child {
  line-height: 1.5rem;
  /* margin: auto 0.65rem auto 0; */
  margin: 0.5rem 0.65rem 0.75rem 0;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: 'Inter';
}

.resultset-item-head > div:nth-child(2) {
  color: #ffffff;
  max-height: 40px;
  background-color: #009FCF ;
  padding: 0.5rem;
  border-radius: 0.375rem;
  margin: auto 0 auto 0.65rem;
}

/* #interview-outer-container > div > label > select {
  border: 2px solid #101E9E;
  padding: 0.25rem 0.25rem;
  border-radius: 0.375rem;
} */

.result-vis-on-xs-only {
  display: inline;
}

.result-vis-on-sm {
  display: none;
}

.resultset-contact-details tr td {
  padding: 0.375rem 0;
  font-family: "Bitter";
}

.resultset-contact-details tr:first-child td {
  padding-top: 0;
}

.resultset-contact-details tr:last-child td {
  padding-bottom: 0;
}

.td-service-type {
  font-weight: 600;
  padding-left: 0!important;
  padding-right: 0!important;
  /* color: #035F4A; */
  color: #334155;
  font-size: 1.25rem;
  padding-top: 1rem;
}

.button-result-detail, .accordion-plus {
  /* background-color: #B24FB0!important;
  background: linear-gradient(159deg, #37A922, #05805F 80%)!important; */
  color: #42279a!important;
  max-height: 40px;
  background-color: #f68ef2!important;
}

.button-result-detail:hover, .accordion-plus:hover {
  color: #210a33!important;
  background-color: #f25fed!important;
}

.mh-service-type-badge span {
  font-size: 0.85rem;
  padding: 0.375rem 0.5rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.button-result-detail {
  cursor: pointer;
}

#generic-dialog span.walk-ins::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(3,95,74)' stroke-width='16' width='18px' height='18px' %3E%3Cpath d='M9.82986 8.78986L7.99998 9.45588V13H5.99998V8.05H6.015L11.2834 6.13247C11.5274 6.03855 11.7922 5.99162 12.0648 6.0008C13.1762 6.02813 14.1522 6.75668 14.4917 7.82036C14.678 8.40431 14.848 8.79836 15.0015 9.0025C15.9138 10.2155 17.3653 11 19 11V13C16.8253 13 14.8823 12.0083 13.5984 10.4526L13.017 13.7497L15 15.67V23H13V17.0142L10.9507 15.0271L10.003 19.3253L3.10938 18.1098L3.45667 16.1401L8.38071 17.0084L9.82986 8.78986ZM13.5 5.5C12.3954 5.5 11.5 4.60457 11.5 3.5C11.5 2.39543 12.3954 1.5 13.5 1.5C14.6046 1.5 15.5 2.39543 15.5 3.5C15.5 4.60457 14.6046 5.5 13.5 5.5Z'%3E%3C/path%3E%3C/svg%3E");
  margin-right: 0.25rem;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  /* transform: rotate(0.75turn); */
  vertical-align: text-top;
  /* margin-top: 2px; */
}

.service-type-description {
  padding: 1rem 0.75rem;
  background-color: #FFE8FE;
  border-radius: 6px;
  margin-left: -0.5rem!important;
  margin-right: -0.5rem!important;
  font-size: 1rem;
  line-height: 1.75rem;
}

#headlessui-dialog-title-\:r1q\: > span {
  line-height: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
}


.mh-resultset-badge-text, .mh-resultset-badge-text span {
  font-size: 1rem!important;
  font-family: "Bitter"!important;
  padding: 0;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {
  .service-type {
    display: inline;
  }

  .result-vis-on-xs-only {
    display: none;
  }

  .result-vis-on-sm {
    display: inline;
  }

.td-service-type{
    padding: 0.375rem 0!important;
  }
}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  .service-type-description {
  background-color: #ffe3fe;
  }

  /* .button-result-detail, .accordion-plus {
    background-color: #B24FB0!important;
    background: linear-gradient(159deg, #B24FB0 0%, #B24FB0 20%, #6B21A8 100%)!important;
  } */

}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
