nav {
  background-color: #ffffff;
}

textarea, input {
  outline: none!important;
}

#interview-outer-container {
  position: absolute;
  left: 0;
  top: 98px;
  margin-top: 0;
  padding-top: 2.25rem;
  padding-bottom: 2rem;
  /* background-image: linear-gradient(#f0fdfa, white 2rem, white 80%, #ccfbf1);
  min-height: calc(100vh - 108px);
  min-height: calc(100dvh - 108px); */
}

#cards-outer-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  max-width: 100%;
}

#cards-outer-container > #interview-outer-container {
  /* top: 104px; */
  margin-top: 0;
  padding-top: 2rem;
}

#cards-outer-container > #interview-outer-container > h1 {
  margin-top: 0.25rem;
  margin-bottom: 0;
}

a.phone-link,
a.external-link,
a.email-link,
a.web-link,
a.form-link,
a.fax-link,
a.generic-link {
  color: #006ECF;
  padding-bottom: 1px;
}

a.external-link,
a.email-link,
a.web-link,
a.form-link,
a.generic-link {
  text-decoration: underline;
}

a.phone-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0, 110, 207)' class='w-6 h-6'%3e%3cpath fill-rule='evenodd' d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z' clip-rule='evenodd' /%3e%3c/svg%3e ");
  margin-right: 0.25rem;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  transform: rotate(0.75turn);
  vertical-align: text-top;
}

a.email-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0, 110, 207)' class='w-6 h-6'%3e%3cpath d='M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z' /%3e%3c/svg%3e ");
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg fill='rgb(0, 110, 207)' version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.9651 7.8302a0.86373 0.81175 42.257 0 0 0.12726 1.4698l8.5081 4.1737 6.1982-6.822a0.86373 0.81176 42.257 0 1 1.2786 1.1616l-6.1982 6.822 4.9679 8.0701a0.86373 0.81175 42.257 0 0 1.4752-0.013834 69.696 65.502 42.257 0 0 5.7654-21.735 0.86373 0.81175 42.257 0 0-1.0382-0.94326 69.694 65.5 42.257 0 0-21.084 7.8168z'/%3E%3C/svg%3E");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
}

a.form-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg fill='rgb(0, 110, 207)' version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m6.5028 5.4591h8.6471a4.0943 4.0943 0 0 1 4.0882 4.0943v11.373a3.6394 3.6394 0 0 0 3.6394-3.6394v-11.697c0-1.8258-1.3648-3.4101-3.2318-3.5666a59.409 59.409 0 0 0-0.81643-0.060656 3.6394 3.6394 0 0 0-3.2305-1.9628h-1.8197a3.6394 3.6394 0 0 0-3.2305 1.9628c-0.27295 0.018197-0.54591 0.03882-0.81643 0.060656-1.8221 0.15407-3.1663 1.6668-3.2293 3.4356zm7.2763-3.6394a1.8197 1.8197 0 0 0-1.8197 1.8197h5.4591a1.8197 1.8197 0 0 0-1.8197-1.8197z' clip-rule='evenodd' fill-rule='evenodd'/%3e%3cpath d='m2.3907 9.0548c0-1.1797 0.95649-2.135 2.135-2.135h11.102c1.1797 0 2.135 0.95649 2.135 2.135v12.81c0 1.1785-0.95649 2.135-2.135 2.135h-11.102a2.135 2.135 0 0 1-2.135-2.135zm3.416 2.989a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.854zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401zm-2.562 3.416a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.854zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401zm-2.562 3.416a0.85401 0.85401 0 0 1 0.85401-0.85401h0.00911a0.85401 0.85401 0 0 1 0.85401 0.85401v0.0091a0.85401 0.85401 0 0 1-0.85401 0.85401h-0.00911a0.85401 0.85401 0 0 1-0.85401-0.85401zm2.562 0a0.85401 0.85401 0 0 1 0.85401-0.85401h4.27a0.85401 0.85401 0 0 1 0 1.708h-4.27a0.85401 0.85401 0 0 1-0.85401-0.85401z' clip-rule='evenodd' fill-rule='evenodd'/%3e%3c/svg%3e");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
  margin-top: 1px;
  margin-bottom: 2px;
  position: relative;
  top: -2px;
}

a.web-link::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(0, 110, 207)'%3e%3cpath stroke='rgb(0, 110, 207)' stroke-width='1' d='M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z' /%3e%3c/svg%3e ");
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
  vertical-align: text-top;
  padding: 1px 0 0 0;
  position: relative;
  top: -2px;
}

a.external-link::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="rgb(0, 110, 207)" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>');
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 0.25rem;
    vertical-align: text-top;
}

ul {
    color: #00A2C0;
    line-height: 1.5rem;
    list-style-type: square!important;
    margin: 0.25rem 0 0 1.25rem!important;
}


ul li span {
    color: #444344;
}

ul.inline-ul li {
    line-height: 1.75rem;
    padding-top: 0.25rem;
}

ul.inline-ul li:first-child {
    padding-top: 0;
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) { }

/* md	- starts at width 768px	 */
@media (min-width: 768px) {

  #interview-outer-container {
    position: relative;
    left: auto;
    top: auto;
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    margin-bottom: 3.5rem;
    border: 1px solid #efefef;
    width: calc(100vw - 4rem);
  }

  .simple-page {
    padding-top: 3rem!important;
  }

  .simple-page #interview-outer-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
  }

  #cards-outer-container {
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
    max-width: 100%;
  }

  #cards-outer-container > #interview-outer-container {
    top: auto;
    /* padding-top: 1rem;
    padding-bottom: 1.5rem; */
  }

  /* #cards-outer-container > #interview-outer-container > div {
    margin-top: -0.75rem;
  } */

}

/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) {

  /* #cards-outer-container > #interview-outer-container > div {
    margin-top: -0.25rem;
  } */

}

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
