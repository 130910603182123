*:focus {
    outline: none;
}

.urgent-help {
  padding-bottom: 1rem;
}

.urgent-help a {
  color: #00847a ;
  text-decoration: none;
}

.urgent-help a.external-link {
  text-decoration: underline;
  color: #00847a ;
}

.urgent-help a.external-link::after {
  content: '';
}

.closeModal {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  z-index: 10000;
}

.helpText a.external-link {
  text-decoration: underline;
  color: #009FCF ;
  /* padding-bottom: 1px;
  border-bottom: 1px solid #009FCF ; */
  z-index: 1000;
}

.helpText a.external-link::after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="rgb(0, 110, 207)" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"></path></svg>');
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 0.25rem;
    vertical-align: text-top;
}

.modal-button button {
  min-width: 100px;
}

 /* Tailwindcss default responsive breakpoints*/
 /* sm	- starts at width 640px	 */
 @media (min-width: 640px) { }

 /* md	- starts at width 768px	 */
 @media (min-width: 768px) {
   .closeModal {
     position: absolute;
     top: 1rem;
     right: 1.125rem;
     width: 2rem;
     height: 2rem;
     cursor: pointer;
   }
 }

 /* lg	- starts at width 1024px	 */
 @media (min-width: 1024px) { }

 /* xl	- starts at width 1280px	 */
 @media (min-width: 1280px) { }

 /* 2xl	- starts at width 1536px	 */
 @media (min-width: 1536px) { }
