.explanation-header {
  font-size: 1rem;
  padding-bottom: 0.5rem;
  font-weight: 700;
}

.explanation-footer {
  font-size: 0.95rem;
  padding-top: 0.75rem;
}

.info-on-toolbar a svg {
  color: #ffffff;
  height: 1.5rem;
  width: 1.5rem;
}

.LTHTooltip_wrapper .LTHTooltip {
  max-width: calc(100vw - 10px);
  font-size: 0.9rem;
}

.LTHTooltip_wrapper .LTHTooltip table {
  border: 1px solid #d6d8d9;
  border-radius: 6px;
  border-collapse: separate;
}

.LTHTooltip_wrapper .LTHTooltip table tr td {
  padding: 2px 8px;
}

.LTHTooltip_wrapper .LTHTooltip table tr td:nth-child(2) {
  padding: 0 8px;
}

.LTHTooltip_wrapper .LTHTooltip table tr:nth-child(odd) {
  background-color: #F1F5F9;
}

.highlightRow {
  color: #a80796;
  font-weight: 700;
}
