#interview {
  min-height: calc(100vh - 104px)!important;
  min-height: calc(100dvh - 104px)!important;
  width: 100%;
  max-width: 100%;
  display: flex;
}

.main-text-frame div p {
  margin-top: 1rem;
}

.main-text-frame div ul ul,
.main-text-frame div ul ol {
  padding-top: 1rem!important;
  list-style: none;
}

.main-text-frame div ul {
  color: rgb(0, 110, 207);
  list-style: square;
  margin-left: 1rem;
}

.main-text-frame div ul li span {
  color: #000;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.main-text-frame div ol li {
  padding-top: 0.25rem;
    margin-left: 1rem;
    padding-bottom: 0.25rem;
}

a.regularLink {
    color: #006ECF;
    padding-bottom: 1px;
    border-bottom: 2px solid #006ECF;
    font-weight: 500;
}

.static-conent {
  line-height: 1.75;
  background-color: white;
  margin-bottom: 3.5rem;
}

ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  list-style-type: none;
  color: black;
  text-indent: -12px;
}
ul.dashed > li:before {
  content: "-";
  color: black;
  /* text-indent: -5px; */
}

/* Tailwindcss default responsive breakpoints*/
/* sm	- starts at width 640px	 */
@media (min-width: 640px) {
  .static-conent {
    background-color: transparent;
    margin-bottom: 0;
  }
}

/* md	- starts at width 768px	 */
@media (min-width: 768px) {
  #interview {
    /* background-image: none;
    background-color: transparent; */
    /* background-image: url('./corrected-03.jpg'); */
    /* background-color: transparent;
    background-size: contain;
    background-position: center 80%;
    background-repeat: no-repeat; */
    /* background-repeat: repeat;
    background-size: contain;
    background-position: bottom right; */
    /* background: rgb(0,97,120);
    background: linear-gradient(90deg, rgba(0,97,120,1) 0%, rgba(0,123,153,1) 55%, rgba(216,43,122,1) 100%); */
    min-height: calc(100vh - 92px)!important;
    min-height: calc(100dvh - 92px)!important;
    background-image: linear-gradient(to left bottom, #fbf1fc, #f3f3ff, #e7f5ff, #ddf8ff, #d8faf9);
  }


  /* #interview {
      position: relative;
      height: 100vh;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('./app-background-19.webp');
      background-size: contain;
  } */
}


/* lg	- starts at width 1024px	 */
@media (min-width: 1024px) { }

/* xl	- starts at width 1280px	 */
@media (min-width: 1280px) { }

/* 2xl	- starts at width 1536px	 */
@media (min-width: 1536px) { }
